<template>
  <div class="content">
    <div class="title title--big title--theme title--indent">Список пользователей</div>
    <app-cells position="start">
      <button @click="onUserAddButtonClick" type="button" class="btn btn--link btn--link-icon">
        <span>Добавить пользователя</span>
      </button>
    </app-cells>
    <app-table-head
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
      @update-limiter="onUpdateLimiter"
    />
    <app-cells position="start">
      <v-select
        @search="onFilterSearch"
        noDrop
        label="id"
        placeholder="Поиск по ФИО или ID"
        class="select select--minw"
      >
      </v-select>
    </app-cells>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      @row-click="onRowClick"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <div slot="last_name" slot-scope="props" class="table-default__left">
        {{ props.row.last_name }} {{ props.row.first_name }} {{ props.row.patronymic }}
      </div>
      <template #delegation="props">
        {{ props.row.delegation ? props.row.delegation.name : '-' }}
      </template>
      <template #groups="props">
        <span
          v-for="group of props.row.groups"
          :key="group.code"
        >
          {{ group.name }}
          <br>
        </span>
      </template>
      <template #is_active="props">
        {{ props.row.is_active ? 'Активный' : 'Неактивный' }}
      </template>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    />
    <app-sidebar-right
      :title="create_user ? 'Добавление пользователя' : 'Редактирование пользователя'"
      :class="{'sidebar-manage--full': show_sidebar}"
      @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form @submit.prevent="onCheckForm">
        <app-form-group label="Имя" label-for="first_name" required>
          <app-input
            v-model="sidebarData.first_name"
            placeholder="Введите имя"
            id="first_name"
            :error="$v.sidebarData.first_name.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.first_name.$dirty && !$v.sidebarData.first_name.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Фамилия" label-for="last_name">
          <app-input
            v-model="sidebarData.last_name"
            placeholder="Введите фамилию"
            id="last_name"
          />
        </app-form-group>
        <app-form-group label="Отчество" label-for="patronymic">
          <app-input
            v-model="sidebarData.patronymic"
            placeholder="Введите отчество"
            id="patronymic"
          />
        </app-form-group>
        <app-form-group label="Телефон" required>
          <app-phone
            v-model="sidebarData.phone"
            placeholder="Введите телефон"
            :error="$v.sidebarData.phone.$dirty && (!$v.sidebarData.phone.required || (sidebarData.phone === 0))"
            ref="phonePicker"
            @paste.native.prevent
          />
          <template #error>
            <div v-if="$v.sidebarData.phone.$dirty && !$v.sidebarData.phone.required">Обязательное поле</div>
            <div v-if="$v.sidebarData.phone.$dirty && (sidebarData.phone === 0) && $v.sidebarData.phone.required">
              Неправильный формат номера
            </div>
          </template>
        </app-form-group>
        <app-form-group label="Пароль" :required="create_user">
          <template #additional>
            <AppButton
              @click="onPasswordGen"
              size="link"
              type="button"
            >
              Сгенерировать пароль
            </AppButton>
          </template>
          <app-input
            v-model="sidebarData.password"
            type="text"
            placeholder="Введите пароль"
            :error="$v.sidebarData.password.$error"
            @change.native="$v.sidebarData.password.$touch()"
          />
<!--          <template #right-place>-->
<!--            <button-->
<!--              @click="show_pass = !show_pass"-->
<!--              type="button"-->
<!--            >-->
<!--              <img v-if="show_pass" src="@/assets/img/eye-open.svg" alt="">-->
<!--              <img v-else src="@/assets/img/eye-close.svg" alt="">-->
<!--            </button>-->
<!--          </template>-->
          <template v-if="create_user" #error>
            <div v-if="$v.sidebarData.password.$dirty && !$v.sidebarData.password.required">Обязательное поле</div>
            <div v-if=" $v.sidebarData.password.$dirty && !$v.sidebarData.password.minLength">
              Длина пароля менее 4 символов
            </div>
          </template>
        </app-form-group>
        <app-form-group label="Электронная почта" label-for="email">
          <app-input
            v-model="sidebarData.email"
            placeholder="Введите электронную почту"
            id="email"
            :error="$v.sidebarData.email.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.email.$dirty && !$v.sidebarData.email.email">Неправильный формат электронной почты</div>
          </template>
        </app-form-group>
        <app-form-group label="Делегация">
          <v-select
            v-model="sidebarData.delegation"
            :reduce="item => item.id"
            :options="delegationList"
            :filterable="true"
            :clearable="false"
            :searchable="true"
            label="name"
            placeholder="Выберите делегацию"
            class="select"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
          </v-select>
        </app-form-group>
        <app-form-group label="Группы" required>
          <v-select
            v-model="sidebarData.groups"
            :reduce="item => item.id"
            :options="groupsList"
            :filterable="false"
            :clearable="false"
            :searchable="false"
            label="name"
            placeholder="Выберите группу"
            class="select select--multiple"
            :class="{ 'select--error': $v.sidebarData.groups.$error }"
            multiple
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
          </v-select>
          <template #error>
            <div v-if="$v.sidebarData.groups.$dirty && !$v.sidebarData.groups.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group>
          <app-checkbox
            label="Активность"
            v-if="!create_user"
            v-model="sidebarData.is_active"
          />
        </app-form-group>
        <app-cells>
          <app-button v-if="!create_user" ref="submit" :disabled="$v.sidebarData.$error || sidebarData.phone === 0">Сохранить</app-button>
          <app-button v-else ref="submit" :disabled="$v.sidebarData.$error || sidebarData.phone === 0">Добавить</app-button>
        </app-cells>
      </form>
    </app-sidebar-right>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import {getCRMUsers, getDelegations, getGroups, postCRMUser, putCRMUser} from '@/http'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'UsersList',
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'last_name', 'phone', 'email', 'delegation', 'groups', 'is_active'],
        options: {
          headings: {
            id: 'ID',
            last_name: 'ФИО',
            phone: 'Телефон',
            email: 'Электронная почта',
            delegation: 'Делегация',
            groups: 'Группа',
            is_active: 'Активность',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: () => 'table-default__row',
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      current_user: null,
      show_sidebar: false,
      create_user: false,
      groupsList: [],
      sidebarData: {},
      show_pass: false,
      delegationList: []
    }
  },
  validations() {
    const data = {
      first_name: { required },
      phone: { required },
      password: { required, minLength: minLength(4) },
      email: { email },
      groups: { required }
    }
    if (!this.create_user) {
      data.password = { minLength: minLength(4) }
    } else {
      data.password = { required, minLength: minLength(4) }
    }
    return { sidebarData: data }
  },
  mounted() {
    this.fetchItems()
    getGroups().then(res => { this.groupsList = res.data })
    getDelegations().then(res => { this.delegationList = res.data })
  },
  methods: {
    onPasswordGen() {
      this.sidebarData.password = ''
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      for (let i = 0, n = charset.length; i < 8; i++) {
        this.sidebarData.password += charset.charAt(Math.floor(Math.random() * n))
      }
    },
    onUserAddButtonClick() {
      this.sidebarData = {password: ''}
      this.$refs.phonePicker._data.phone = ''
      this.$v.sidebarData.$reset()
      this.create_user = true
      this.show_sidebar = true
    },
    fetchItems(page = this.pagination.page, page_size = 20, search = '') {
      getCRMUsers({ page, page_size, search })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.pagination.page = 1
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems(this.pagination.page, this.table_limit)
    },
    onRowClick(row) {
      this.create_user = false
      this.show_sidebar = true
      this.$v.sidebarData.$reset()
      this.sidebarData = { ...row.row, password: '' }
    },
    onClickPagination(page) {
      this.fetchItems(page, this.table_limit)
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(1, vm.table_limit, search)
      loading(false)
    }, 350),
    onCheckForm() {
      this.$v.sidebarData.$touch()
      if (this.$v.sidebarData.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (!this.create_user) {
        putCRMUser(this.sidebarData.id, this.normalizeForm())
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Пользователь обновлен'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      } else {
        postCRMUser(this.normalizeForm())
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Пользователь добавлен'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      }
    },
    normalizeForm() {
      const normalizedForm = { ...this.sidebarData }

      normalizedForm.groups = normalizedForm.groups.map(item => {
        if (typeof item === 'object') return item.id
        else return item
      })
      if (typeof normalizedForm.delegation === 'object') normalizedForm.delegation = normalizedForm.delegation.id

      return normalizedForm
    },
  }
}
</script>
